import { createSelector } from '@ngrx/store';
import { ActionTypes, TypeAction } from './guidance.action';
import { IRootState } from '../../../store';

export interface IState {
  isViewed: boolean;
}

const initialState: IState = {
  isViewed: false
};

export function guidanceReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_GUIDANCE_STORAGE: {

      return Object.assign(
        {},
        state,
        { isViewed: payload }
      );
    }
    default: {
      return state;
    }
  }
}

export const getIsGuidanceViewed = createSelector(
  ({ guidance }: IRootState): IState => guidance,
  ({ isViewed }: IState): boolean => isViewed
);
