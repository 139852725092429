import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';
import { Observable  } from 'rxjs';
import { AppService } from '../../../../app.service';


export interface IProfilePhotoResponse {
  photo: string;
  status: number;
}

@Injectable()
export class ProfilePhotoService extends AbstractServerInteractionService {
  protected get url(): string {
    return this.appService.getApiUrls().profilePhoto;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getProfilePhoto(): Observable<IProfilePhotoResponse | any> {
    return this.http.get(this.url);
  }

  public confirmProfilePhoto(photo): Observable<IProfilePhotoResponse | any> {
    const userImageBase64 = photo ? photo.split(',')[1] : '';

    return this.http.post(this.url, { photo: userImageBase64 });
  }
}
