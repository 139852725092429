import { Action } from '@ngrx/store';
import { type } from '../../../../util';

export const ActionTypes = {
  GET_I9_ERROR_MESSAGE: type('[Workday rehire error] get error message'),
  SET_I9_ERROR_MESSAGE: type('[Workday rehire error] set error message'),
  REHIRE_EMPLOYEE: type('[Workday rehire error] submit form')
};

export class GetWorkdayRehireErrorMessage implements Action {
  public type = ActionTypes.GET_I9_ERROR_MESSAGE;

  constructor(public payload?: any) {
  }
}

export class SetWorkdayRehireErrorMessage implements Action {
  public type = ActionTypes.SET_I9_ERROR_MESSAGE;

  constructor(public payload: string) {
  }
}

export class RehireEmployee implements Action {
  public type = ActionTypes.REHIRE_EMPLOYEE;

  constructor(public payload?: any) {
  }
}

export type TypeAction =
  GetWorkdayRehireErrorMessage |
  SetWorkdayRehireErrorMessage |
  RehireEmployee;
