import { createSelector } from '@ngrx/store';
import { ActionTypes, TypeAction } from './workday-rehire-error.actions';
import { IRootState } from '../../../../store/index';

export interface IState {
  errorMessage: string | null;
}

const initialState: IState = {
  errorMessage: null
};

export function workdayRehireErrorReducer(
  state: IState = initialState,
  action: TypeAction
): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_I9_ERROR_MESSAGE: {
      return Object.assign({}, state, {
        errorMessage: payload
      });
    }

    default: {
      return state;
    }
  }
}

export const getWorkdayRehireErrorMessage = createSelector(
  ({ workdayRehireError }: IRootState) => workdayRehireError,
  ({ errorMessage }: IState): string => errorMessage
);
