import { Action } from '@ngrx/store';
import { type } from '../../../util';

export const ActionTypes = {
  SET_POPUP_OPEN_STATE: type('[Introduction popup] Set popup open state'),
  UPDATE_VIDEO_POSITION: type('[Introduction popup] update video position'),
  UPDATE_VIDEO_QUALITY: type('[Introduction popup] update video quality'),
  GET_VIDEO_STORAGE_STATE: type('[Introduction popup] get video storage state'),
  SET_VIDEO_STORAGE_STATE: type('[Introduction popup] set video storage state'),
  FETCH_VIDEOS: type('[Introduction popup] fetch videos'),
  STORE_VIDEOS: type('[Introduction popup] store videos'),
};

export class SetIntroPopupOpenState implements Action {
  public type = ActionTypes.SET_POPUP_OPEN_STATE;

  constructor(public payload: boolean) {}
}

export class UpdateVideoPosition implements Action {
  public type = ActionTypes.UPDATE_VIDEO_POSITION;

  constructor(public payload: number) {}
}

export class UpdateVideoQuality implements Action {
  public type = ActionTypes.UPDATE_VIDEO_QUALITY;

  constructor(public payload: string) {}
}

export class GetIntroVideoStorageState implements Action {
  public type = ActionTypes.GET_VIDEO_STORAGE_STATE;

  constructor(public payload?: any) {}
}

export class SetIntroVideoStorageState implements Action {
  public type = ActionTypes.SET_VIDEO_STORAGE_STATE;

  constructor(public payload: boolean | null) {}
}

export class FetchIntroVideos implements Action {
  public type = ActionTypes.FETCH_VIDEOS;

  constructor(public payload?: any) {}
}

export class StoreIntroVideo implements Action {
  public type = ActionTypes.STORE_VIDEOS;

  constructor(public payload: any) {}
}

export type TypeAction =
  SetIntroPopupOpenState |
  UpdateVideoPosition |
  UpdateVideoQuality |
  GetIntroVideoStorageState |
  SetIntroVideoStorageState |
  FetchIntroVideos |
  StoreIntroVideo;
