import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService, combineHeadersOptions,
  commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';
import { Observable, catchError, of, throwError } from 'rxjs';
import {
  IWDErrorFormData
} from '../../../common/tasks/workday-account/workday-account-error/workday-account-error.reducer';
import { IWDErrorOptionsResponse, IWDErrorResponseForm } from './interface';
import { getFormatedSsn } from '../../../util';
import { AppService } from '../../../../app.service';

@Injectable()
export class WorkdayAccountService extends AbstractServerInteractionService {
  protected get url(): {
    task: string,
    options: string,
    rehire: string,
  } {
    return this.appService.getApiUrls().workdayAccount;
  }

  constructor(protected appService: AppService,
    protected http: HttpClient) {
    super();
  }

  public getWorkdayAccountInfo() {
    return this.http.get(this.url.task);
  }

  public getOptions(): Observable<IWDErrorOptionsResponse | any> {
    return this.http.get(this.url.options);
  }

  public submitForm(formData: IWDErrorFormData, isCanada: boolean) {
    return this.http.post(
      this.url.task,
      this.formatDataToSend(formData, isCanada),
      {headers: combineHeadersOptions(commonErrorHandlingDisabled)}
    ).pipe(
      catchError((error) => throwError(() =>(error.json()))));
  }

  public rehireEmployee() {
    return this.http.post(this.url.rehire, null, {headers: combineHeadersOptions(commonErrorHandlingDisabled)})
      .pipe(catchError(() => of(false)));
  }

  public formatDataToSend(formData: IWDErrorFormData, isCanada: boolean): IWDErrorResponseForm {
    return {
      ssn: getFormatedSsn(formData.ssn),
      dateOfBirth: formData.dateOfBirth,
      addressLine: formData.addressLine1,
      addressLine2: formData.addressLine2,
      city: formData.city,
      stateId: isCanada ? null : formData.countryState,
      provinceId: isCanada ? formData.countryState : null,
      zip: formData.postalCode,
      email: formData.email,
      preferredPhone: formData.primaryPhone ? {
        phoneNumber: formData.primaryPhone,
        typeId: formData.primaryPhoneType
      } : null,
      alternatePhone: formData.alternatePhone ? {
        phoneNumber: formData.alternatePhone,
        typeId: formData.alternatePhoneType
      } : null,
      genderId: Number(formData.gender),
      militaryStatusId: formData.militaryStatus,
      maritalStatusId: formData.maritalStatus,
      ethnicityId: formData.ethnicity,
      citizenshipId: formData.citizenship,
    };
  }
}
