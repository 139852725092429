import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';

import { enumHasValue } from '../../../util';
import {
  VideoQualitiesEnum
} from './interfaces';
import { EMPTY, map } from 'rxjs';
import { AppService } from '../../../../app.service';

@Injectable()
export class MediaService extends AbstractServerInteractionService {
  protected get url(): string {
    return this.appService.getApiUrls().media;
  }

  constructor(
    protected appService: AppService,
    protected http: HttpClient
  ) {
    super();
  }

  private serviceUnavailable: boolean = true;

  public getIntroVideo() {
    // TODO: remove serviceUnavailable functionality after BE Fix OT-4431
    if (this.serviceUnavailable) {
      return EMPTY;
    }

    return this.http.get(this.url).pipe(map(this.getIntroData));
  }

  private getIntroData(response) {
    const videos = {};

    response.forEach(({ quality, url }) => {
      if (!videos[quality] && enumHasValue(VideoQualitiesEnum, quality)) {
        videos[quality] = url;
      }
    });

    const qualityList = Object.keys(videos).sort();
    const currentVideoQuality = qualityList.find((elem) => elem === VideoQualitiesEnum.SD)
      || qualityList[0]
      || null;
    const currentVideoUrl = videos[currentVideoQuality] || null;

    return {
      videos,
      qualityList,
      currentVideoUrl,
      currentVideoQuality,
      currentVideoPosition: 0
    };
  }
}
