<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<header class="header">
  <div class="header__navigation">
    <div class="header__toggle-wrapper"
         *ngIf="(isLogged$ | async) && !(isAgreementPage$ | async)"
         (click)="openSidebar($event)">
      <span class="header__menu-toggle"></span>
    </div>

    <a class="header__logos" title="Vivint Logo" [routerLink]="'/'">
      <img src="/assets/img/vivint-logo.svg" alt="Vivint Corporate Logo" class="header__logo">
    </a>
  </div>

  <div class="header__buttons" *ngIf="!(isServiceOutage$ | async)">
    <ot-tip class="header__insider-tip"
            *ngIf="!(isGuidanceAvailable$ | async) && !(isInsiderTipViewed$ | async) && (isWDCompleted$ | async) && (isLogged$| async)"
            [text]="getLinkConfig(isTech$ | async).tip"
            [isOpen]="isInsiderTipPopupOpen$ | async"
            (onOpen)="onInsiderTipOpen()"
            (onClose)="onInsiderTipClose()"></ot-tip>
    <a [href]="getLinkConfig(isTech$ | async).url"
       class="header__insider"
       *ngIf="(isWDCompleted$ | async) && (isLogged$| async)"
       target="_blank"
       (click)="onInsiderLinkClick()">{{ getLinkConfig(isTech$ | async).name }}</a>
    <div class="header__contacts" #needHelp>
    <span class="header__need-help" (click)="toggleHelp($event)" [class]="{ active: isHelpVisible }">
      Need Help?
    </span>
      <div class="header__help-info" [class]="{ visible: isHelpVisible }">
        <div class="manager header__manager" *ngIf="(manager$ | async)?.employeeId">
          <div class="manager__info-container">
            <div class="manager__info">
              <div class="manager__photo"
                   [ngStyle]="{'background-image': 'url(' + (manager$ | async)?.imageUrl + ')'}"></div>
              <div class="manager__details">
                Hi! My name is {{ (manager$ | async)?.firstName }} {{ (manager$ | async)?.lastName }}. I’m your manager.
                If you have any questions, feel free to ask me.
              </div>
            </div>
            <div class="manager__contacts">
              <a href="tel:{{ (manager$ | async)?.phone }}" class="manager__phone" *ngIf="(manager$ | async)?.phone">
                {{ (manager$ | async)?.phone | otPhone }}
              </a>
              <span class="manager__email icon-mail" *ngIf="(manager$ | async)?.email">
              {{ (manager$ | async)?.email }}
            </span>
            </div>
          </div>
          <div class="manage-one-stop manager__one-stop">
            <p class="manage-one-stop__description">
              Or call 1Stop:
            </p>
            <a href="tel:{{ (supportContacts$ | async)?.mobile1StopPhoneNumber }}" class="manage-one-stop__tel">
              {{ (supportContacts$ | async)?.mobile1StopPhoneNumber }}
            </a>
          </div>
        </div>
        <div class="one-stop" *ngIf="!(manager$ | async)?.employeeId">
          <div class="header__close-help-info" (click)="toggleHelp($event)"></div>
          <p class="one-stop__description">
            Calls support service 1Stop if you have questions:
          </p>
          <a href="tel:{{ (supportContacts$ | async)?.mobile1StopPhoneNumber }}" class="one-stop__tel">
            {{ (supportContacts$ | async)?.mobile1StopPhoneNumber }}
          </a>
        </div>
      </div>
    </div>
    <div class="header__auth" *ngIf="(isLogged$ | async) && !(isAgreementPage$ | async)">
      <span class="header__login" (click)="logOut()">Log out</span>
    </div>
  </div>
</header>
