import { Action } from '@ngrx/store';
import { type } from '../../../../util';
import { IFormInputChangePayload } from '../../../abstract.components/abstract.form.component';
import {
  FieldsAsyncValidationEnum,
  ISelectOptions,
  queryStateEnum,
  TipTypesEnum
} from './workday-account-error.reducer';
import { ValidationTypesEnum } from '../../../../core/services/employee/employee.service';

export const ActionTypes = {
  FORM_CONTROL_CHANGE: type('[WorkDay account error] form control change'),
  QUERY_SEARCH: type('[WorkDay account error] query search'),
  SUBMIT_WD: type('[WorkDay account error] submit form'),
  FETCH_SELECT_MODELS: type('[WorkDay account error] fetch options'),
  STORE_SELECT_MODELS: type('[WorkDay account error] store options'),
  STORE_FORM_DATA: type('[WorkDay account error] store form data'),
  SET_MESSAGE: type('[WorkDay account error] set error message'),
  VALIDATE_SSN_DOB: type('[WorkDay account error] validate ssn and dob'),
  STORE_SSN_DOB_VALIDITY: type('[WorkDay account error] store ssn and dob validity'),
  SET_SSN_DOB_LOADING: type('[WorkDay account error] set ssn and dob loading'),
  SET_TIP_STATE: type('[WorkDay account error] set tip state'),
  SET_FIELD_LOADING_STATE: type('[WorkDay account error] set field loading state'),
  VALIDATE_PHONE: type('[WorkDay account error] validate phone'),
  SET_PHONE_VALIDATION: type('[WorkDay account error] set phone validation'),
  INITIAL_PHONE_VALIDATION: type('[WorkDay account error] initial phone validation'),
};

export class WorkdayAccountErrorControlChange implements Action {
  public type = ActionTypes.FORM_CONTROL_CHANGE;

  constructor(public payload: IFormInputChangePayload) {
  }
}

export class SetWorkdayErrorMessage implements Action {
  public type = ActionTypes.SET_MESSAGE;

  constructor(public payload: string) {
  }
}

export class QuerySearch implements Action {
  public type = ActionTypes.QUERY_SEARCH;

  constructor(public payload: {
    field: queryStateEnum,
    value: string
  }) {
  }
}

export class WorkdayAccountErrorSubmit implements Action {
  public type = ActionTypes.SUBMIT_WD;

  constructor(public payload?: any) {
  }
}

export class FetchWorkdayOptions implements Action {
  public type = ActionTypes.FETCH_SELECT_MODELS;

  constructor(public payload?: any) {
  }
}

export class StoreWorkdayOptions implements Action {
  public type = ActionTypes.STORE_SELECT_MODELS;

  constructor(public payload: ISelectOptions) {
  }
}

export class StoreWorkdayFormData implements Action {
  public type = ActionTypes.STORE_FORM_DATA;

  constructor(public payload?: any) {
  }
}

export class ValidateSsnDob implements Action {
  public type = ActionTypes.VALIDATE_SSN_DOB;

  constructor(public payload: {
    ssn?: string;
    sin?: string;
    captcha: string;
  }) {}
}

export class StoreSsnDobValidity implements Action {
  public type = ActionTypes.STORE_SSN_DOB_VALIDITY;

  constructor(public payload: {
    valid: boolean;
    message: any;
  }) {}
}

export class SetTipState implements Action {
  public type = ActionTypes.SET_TIP_STATE;

  constructor(public payload: {
    type: TipTypesEnum,
    state: boolean
  }) {
  }
}

export class SetFieldLoadingState implements Action {
  public type = ActionTypes.SET_FIELD_LOADING_STATE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    isLoading: boolean
  }) {
  }
}

export class ValidatePhone implements Action {
  public type = ActionTypes.VALIDATE_PHONE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    phone: string
  }) {
  }
}

export class SetPhoneValidation implements Action {
  public type = ActionTypes.SET_PHONE_VALIDATION;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    valid: ValidationTypesEnum
  }) {
  }
}

export class InitialPhoneValidation implements Action {
  public type = ActionTypes.INITIAL_PHONE_VALIDATION;

  constructor(public payload?: any) {
  }
}

export type TypeAction =
  WorkdayAccountErrorControlChange |
  QuerySearch |
  WorkdayAccountErrorSubmit |
  StoreWorkdayOptions |
  ValidateSsnDob |
  StoreSsnDobValidity |
  SetTipState |
  SetFieldLoadingState |
  ValidatePhone |
  SetPhoneValidation |
  InitialPhoneValidation |
  SetWorkdayErrorMessage |
  StoreWorkdayFormData;
