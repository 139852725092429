import { createSelector } from '@ngrx/store';
import {
  ActionTypes,
  TypeAction
} from './workday-account-error.actions';
import { IRootState } from '../../../../store';
import {
  createSelectModelFromAnyArray,
  createSelectModelFromStringArray,
  ICreateSelectModelPropsSetting
} from '../../../../shared/components/select/models/createSelectModel';
import { IOption } from '../../../../shared/components/select';
import { ValidationTypesEnum } from '../../../../core/services/employee/employee.service';

export enum FieldsAsyncValidationEnum {
  ssnAndDob,
  primaryPhone,
  alternatePhone,
  emergencyPrimaryPhone,
  emergencyAlternatePhone,
}

export enum TipTypesEnum {
  antiDescrNotice,
  ethnicity
}

export interface ISelectOptions {
  phoneType: any[];
  state: any[];
  gender: any[];
  ethnicity: any[];
  citizenship: any[];
  maritalStatus: any[];
  militaryStatus: any[];
}

export interface ISelectModels {
  phoneType: IOption[];
  state: IOption[];
  gender: IOption[];
  ethnicity: IOption[];
  citizenship: IOption[];
  maritalStatus: IOption[];
  militaryStatus: IOption[];
}

export interface ISsnDobValidity {
  valid: boolean;
  message: string | null;
}

export interface IFieldsLoadingProgressState {
  ssnAndDob: boolean;
  primaryPhone: boolean;
  alternatePhone: boolean;
  emergencyPrimaryPhone: boolean;
  emergencyAlternatePhone: boolean;
}

export enum queryStateEnum {
  state
}

export interface IQueryState {
  state: string;
}

export interface IWDErrorFormData {
  dateOfBirth: string;
  ssn: string;
  gender: string;
  ethnicity: number | null;
  citizenship: number | null;
  maritalStatus: number | null;
  militaryStatus: number | null;
  email: string;
  primaryPhone: string;
  primaryPhoneType: number | null;
  alternatePhone: string;
  alternatePhoneType: number | null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryState: number | null;
  postalCode: string;
}

export interface IState {
  formData: IWDErrorFormData;
  selectOptions: ISelectOptions;
  queryState: IQueryState;
  errorMessage: string;
  ssnDobValidity: ISsnDobValidity;
  fieldsLoadingProgress: IFieldsLoadingProgressState;
  isDataFetched: boolean;
  tip: {
    antiDescrNotice: boolean;
    ethnicity: boolean;
  };
  phonesValidity: {
    primaryPhone: ValidationTypesEnum;
    alternatePhone: ValidationTypesEnum;
    emergencyPrimaryPhone: ValidationTypesEnum;
    emergencyAlternatePhone: ValidationTypesEnum;
  };
}

const initialState: IState = {
  formData: {
    dateOfBirth: '',
    ssn: '',
    gender: '',
    ethnicity: null,
    citizenship: null,
    maritalStatus: null,
    militaryStatus: null,
    email: '',
    primaryPhone: '',
    primaryPhoneType: null,
    alternatePhone: null,
    alternatePhoneType: null,
    addressLine1: '',
    addressLine2: '',
    city: '',
    countryState: null,
    postalCode: ''
  },
  selectOptions: {
    phoneType: [],
    state: [],
    gender: [],
    ethnicity: [],
    citizenship: [],
    maritalStatus: [],
    militaryStatus: [],
  },
  queryState: {
    state: ''
  },
  errorMessage: 'something went wrong...',
  ssnDobValidity: {
    valid: true,
    message: null,
  },
  fieldsLoadingProgress: {
    ssnAndDob: false,
    primaryPhone: false,
    alternatePhone: false,
    emergencyPrimaryPhone: false,
    emergencyAlternatePhone: false
  },
  isDataFetched: false,
  tip: {
    antiDescrNotice: false,
    ethnicity: false,
  },
  phonesValidity: {
    primaryPhone: ValidationTypesEnum.initial,
    alternatePhone: ValidationTypesEnum.initial,
    emergencyPrimaryPhone: ValidationTypesEnum.initial,
    emergencyAlternatePhone: ValidationTypesEnum.initial,
  }
};

function getUpdatedQueryState({ queryState }: IState, query, propToUpdate: queryStateEnum) {
  return {
    ...queryState,
    [queryStateEnum[propToUpdate]]: query
  };
}

export function workdayAccountErrorReducer(state: IState = initialState,
                                           action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.FORM_CONTROL_CHANGE: {
      const { formData } = state;
      const { fieldId, value } = payload;
      const result = Object.assign({}, formData, { [fieldId]: value });

      return Object.assign({}, state, { formData: result });
    }

    case ActionTypes.QUERY_SEARCH: {
      const result = getUpdatedQueryState(state, payload.value, payload.field);

      return Object.assign({}, state, { queryState: result });
    }

    case ActionTypes.SET_MESSAGE: {
      return Object.assign({}, state, {
        errorMessage: payload
      });
    }

    case ActionTypes.STORE_SELECT_MODELS: {
      return Object.assign({}, state, { selectOptions: payload });
    }

    case ActionTypes.STORE_FORM_DATA: {
      return Object.assign({}, state, { formData: payload, isDataFetched: true });
    }

    case ActionTypes.STORE_SSN_DOB_VALIDITY: {
      const { ssnDobValidity }: IState = state;
      const result = {
        ...ssnDobValidity,
        ...payload,
      };

      return Object.assign({}, state, { ssnDobValidity: result });
    }

    case ActionTypes.SET_FIELD_LOADING_STATE: {
      const { fieldsLoadingProgress }: IState = state;
      const result = {
        ...fieldsLoadingProgress,
        [FieldsAsyncValidationEnum[payload.field]]: payload.isLoading
      };

      return Object.assign({}, state, { fieldsLoadingProgress: result });
    }

    case ActionTypes.SET_TIP_STATE: {
      return Object.assign({}, state, { tip: {
          ...state.tip,
          [TipTypesEnum[payload.type]]: payload.state
        }
      });
    }

    case ActionTypes.SET_PHONE_VALIDATION: {
      const { phonesValidity }: IState = state;
      const changes = {
        ...phonesValidity,
        [FieldsAsyncValidationEnum[payload.field]]: payload.valid
      };

      return Object.assign({}, state, { phonesValidity: changes });
    }

    default: {
      return state;
    }
  }
}

export const getWorkdayAccountErrorFormData = createSelector(
  ({ workdayAccountError }: IRootState): IState => workdayAccountError,
  ({ formData }: IState): IWDErrorFormData => formData
);

export const getSelectModels = createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  ({ selectOptions }: IState): ISelectModels => {
    const selectSetting: ICreateSelectModelPropsSetting = {
      valueKey: 'value',
      labelKey: 'text'
    };

    const selectModels = Object.keys(selectOptions).reduce((acc, key) => {
      const propValue = typeof selectOptions[key][0] !== 'object' ?
        createSelectModelFromStringArray(selectOptions[key]) :
        createSelectModelFromAnyArray(selectOptions[key], selectSetting);

      acc[key] = propValue;

      return acc;
    }, Object.assign({}, selectOptions));

    return selectModels;
  }
);

export const getFilteredStates = createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  (state: IState): IOption[] => {
    const {
      selectOptions: { state: countryStates },
      queryState: { state: query }
    } = state;

    return countryStates
      .filter((countryState) => countryState.text
        .toLowerCase()
        .includes(query.toLowerCase()))
      .map(({ value, text }) => ({ value, label: text }));
  }
);

export const getErrorMessage = createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  ({ errorMessage }: IState): string => errorMessage
);

export const getSsnDobValidity = createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  ({ ssnDobValidity }: IState): ISsnDobValidity => ssnDobValidity
);

export const getIsSsnDobLoading = createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  ({ fieldsLoadingProgress }: IState): boolean =>
    fieldsLoadingProgress.ssnAndDob
);

export const getIsWorkdayDataFetched = createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  ({ isDataFetched }: IState): boolean => isDataFetched
);

export const getIsTipOpen = (type: TipTypesEnum) => createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  ({ tip }: IState) => tip[TipTypesEnum[type]]
);

export const getIsFieldLoading = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  ({ fieldsLoadingProgress }: IState) => fieldsLoadingProgress[FieldsAsyncValidationEnum[field]]
);
export const getPhoneValidity = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ workdayAccountError }: IRootState) => workdayAccountError,
  ({ phonesValidity }: IState) => phonesValidity[FieldsAsyncValidationEnum[field]]
);
