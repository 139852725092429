import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';
import { Observable } from 'rxjs';
import { AppService } from '../../../../app.service';

export interface IDriverLicenseResponse {
  status: number;
  isSubmitted: boolean;
}

@Injectable()
export class DriverLicenseService extends AbstractServerInteractionService {
  protected get url(): string {
    return this.appService.getApiUrls().driverLicense;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public confirmDriverLicense({ firstPhotoBase64, secondPhotoBase64 }: {
    firstPhotoBase64: string,
    secondPhotoBase64: string,
  }): Observable<IDriverLicenseResponse | any> {
    const cutBase64 = (img: string) => img && img.split ? img.split(',')[1] : '';

    return this.http.post(this.url, {
      firstPhoto: cutBase64(firstPhotoBase64),
      secondPhoto: cutBase64(secondPhotoBase64)
    });
  }
}
