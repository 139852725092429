import { createSelector } from '@ngrx/store';
import { ActionTypes, TypeAction } from './pii-info-popup.actions';
import { IRootState } from '../../../store';

export interface ISsnDobValidity {
  valid: boolean;
  message: string | null;
}

export interface IFieldsLoadingProgressState {
  ssnAndDob: boolean;
}

export interface IPiiInfoPopupFormData {
  dateOfBirth: string;
  ssn: string;
}

export interface IState {
  formData: IPiiInfoPopupFormData;
  ssnDobValidity: ISsnDobValidity;
  fieldsLoadingProgress: IFieldsLoadingProgressState;
}

const initialState: IState = {
  formData: {
    dateOfBirth: '',
    ssn: '',
  },
  ssnDobValidity: {
    valid: true,
    message: null,
  },
  fieldsLoadingProgress: {
    ssnAndDob: false
  }
};

export function piiInfoPopupReducer(state: IState = initialState,
                                    action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.FORM_CONTROL_CHANGE: {
      const { formData } = state;
      const { fieldId, value } = payload;
      const result = Object.assign({}, formData, { [fieldId]: value });

      return Object.assign({}, state, { formData: result });
    }

    case ActionTypes.STORE_SSN_DOB_VALIDITY: {
      const { ssnDobValidity }: IState = state;
      const result = {
        ...ssnDobValidity,
        ...payload,
      };

      return Object.assign({}, state, { ssnDobValidity: result });
    }

    case ActionTypes.SET_SSN_DOB_LOADING: {
      const { fieldsLoadingProgress }: IState = state;
      const result = {
        ...fieldsLoadingProgress,
        ssnAndDob: payload
      };

      return Object.assign({}, state, { fieldsLoadingProgress: result });
    }

    default: {
      return state;
    }
  }
}

export const getPiiInfoPopupFormData = createSelector(
  ({ piiInfo }: IRootState): IState => piiInfo,
  ({ formData }: IState): IPiiInfoPopupFormData => formData
);

export const getSsnDobValidity = createSelector(
  ({ piiInfo }: IRootState) => piiInfo,
  ({ ssnDobValidity }: IState): ISsnDobValidity => ssnDobValidity
);

export const getIsSsnAndDobLoading = createSelector(
  ({ piiInfo }: IRootState) => piiInfo,
  ({ fieldsLoadingProgress }: IState): boolean =>
    fieldsLoadingProgress.ssnAndDob
);
