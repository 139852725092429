import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import {
  getHelpVisibility,
  getIsLogged,
  getManager,
  getSupportContacts,
  getIsInsiderTipViewed,
  getIsInsiderTipPopupOpen,
  getServiceStatus
} from '../../../app.reducer';
import {
  HideInsiderTip,
  LogOut,
  ShowInsiderTip,
  ToggleNeedHelp,
  ToggleSideBar
} from '../../../app.actions';
import { ISupportContacts } from '../../services/supportContacts/interfaces';
import { Router } from '@angular/router';
import { IManagerProfileViewModel } from '../../services/managers/interfaces';
import {
  getIsGuidanceAvailable,
  getIsTaskComplete,
  getIsTech,
} from '../../../common/tasks/tasks.reducer';
import { TasksUrlsEnum } from '../../../common/tasks/constants';
import { ServiceStatusEnum } from '../../../constants';
import {
  eventCategory,
  sendGoogleAnalyticsEvent
} from '../../../util';

export interface ILinkConfig {
  url: string;
  tip: string;
  name: string;
}

const linkConfigs: {
  tech: ILinkConfig,
  sales: ILinkConfig
} = {
  tech: {
    url: 'https://prosource.vivint.com',
    tip: 'You\'ve unlocked Prosource!' +
      ' Once you complete the remaining onboarding tasks, click here to access your tech tools.',
    name: 'Go to Prosource'
  },
  sales: {
    url: 'https://insider.vivint.com/login',
    tip: 'You\'ve unlocked Insider!' +
      ' Once you complete the remaining onboarding tasks, click here to access your sales tools',
    name: 'Go to Insider'
  }
};

export const getLinkConfig = (isTech): ILinkConfig => {
  return isTech ? linkConfigs.tech : linkConfigs.sales;
};

@Component({
  selector: 'ot-header',
  templateUrl: './header.html',
  styleUrls: ['header.scss']
})
export class HeaderComponent implements OnInit {
  public isHelpVisible: boolean;
  public manager$: Observable<IManagerProfileViewModel> = this.store.select(getManager);
  public isLogged$: Observable<boolean> = this.store.select(getIsLogged);
  public supportContacts$: Observable<ISupportContacts> = this.store.select(getSupportContacts);
  public isAgreementPage$: Observable<boolean> = this.router.events.pipe(map(
    () => this.router.url.split('/')[1] === 'agreement'));
  public isWDCompleted$: Observable<boolean> = this.store.select(getIsTaskComplete(TasksUrlsEnum.WorkdayAccount));
  public isInsiderTipPopupOpen$: Observable<boolean> = this.store.select(getIsInsiderTipPopupOpen);
  public isInsiderTipViewed$: Observable<boolean> = this.store.select(getIsInsiderTipViewed);
  public isServiceOutage$: Observable<boolean> = this.store.select(getServiceStatus)
    .pipe(map((status) => status === ServiceStatusEnum.unavailable));
  public isGuidanceAvailable$: Observable<boolean> = this.store.select(getIsGuidanceAvailable);
  public isTech$: Observable<boolean> = this.store.select(getIsTech);

  @ViewChild('needHelp') private needHelp: ElementRef;

  @HostListener('document:click', ['$event'])
  private onDocumentClick($event) {
    if (this.isHelpVisible && !this.needHelp.nativeElement.contains($event.target)) {
      this.store.dispatch(new ToggleNeedHelp());
    }
  }

  @HostListener('document:touchstart', ['$event'])
  private onDocumentTouch($event) {
    if (this.isHelpVisible && !this.needHelp.nativeElement.contains($event.target)) {
      this.store.dispatch(new ToggleNeedHelp());
    }
  }

  constructor(protected store: Store<any>,
              protected router: Router) {
  }

  public getLinkConfig(isTech: boolean): ILinkConfig {
    return getLinkConfig(isTech);
  }

  public ngOnInit() {
    this.store.select(getHelpVisibility).subscribe(
      (isHelpVisible) => this.isHelpVisible = isHelpVisible
    );
  }

  public toggleHelp(event) {
    event.stopPropagation();

    sendGoogleAnalyticsEvent({
      eventCategory: eventCategory.click,
      eventLabel: 'Need help',
      eventAction: 'Need help toggled',
      eventValue: 1
    });

    this.store.dispatch(new ToggleNeedHelp());
  }

  public logOut() {
    this.store.dispatch(new LogOut());
  }

  public redirectToHome() {
    this.router.navigate(['/']);
  }

  public openSidebar(event) {
    event.stopPropagation();

    this.store.dispatch(new ToggleSideBar(true));
  }

  public onInsiderTipOpen() {
    this.store.dispatch(new ShowInsiderTip());
  }

  public onInsiderTipClose() {
    this.store.dispatch(new HideInsiderTip());
  }

  public onInsiderLinkClick() {
    this.onInsiderTipClose();

    sendGoogleAnalyticsEvent({
      eventCategory: eventCategory.click,
      eventLabel: 'Insider',
      eventAction: 'Insider link clicked',
      eventValue: 1
    });
  }
}
