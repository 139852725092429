import { Action } from '@ngrx/store';
import { type } from '../../../util';

export const ActionTypes = {
  GET_GUIDANCE_FROM_STORAGE: type('[Guidance] get local storage value'),
  SET_GUIDANCE_STORAGE: type('[Guidance] set local storage value'),
  COMPLETE_GUIDANCE: type('[Guidance] complete guidance'),
};

export class GetGuidanceStorageInfo implements Action {
  public type = ActionTypes.GET_GUIDANCE_FROM_STORAGE;

  constructor(public payload?: any) {}
}

export class SetGuidanceStorageInfo implements Action {
  public type = ActionTypes.SET_GUIDANCE_STORAGE;

  constructor(public payload: boolean) {}
}

export class CompleteGuidance implements Action {
  public type = ActionTypes.COMPLETE_GUIDANCE;

  constructor(public payload?: any) {}
}

export type TypeAction =
  GetGuidanceStorageInfo |
  SetGuidanceStorageInfo;
