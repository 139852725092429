import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store';
import { TypeAction, ActionTypes } from './introduction-popup.actions';

export interface IState {
  videos: {
    hd?: string;
    sd?: string;
  };
  currentVideoUrl: string;
  qualityList: string[];
  currentVideoPosition: number;
  currentVideoQuality: string;
  isOpen: boolean;
  isInitialViewed: boolean;
}

const initialState: IState = {
  videos: {
    hd: '',
    sd: ''
  },
  currentVideoUrl: '',
  qualityList: [],
  currentVideoPosition: 0,
  currentVideoQuality: '',
  isOpen: false,
  isInitialViewed: false,
};

export function introductionPopupReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_VIDEOS: {
      return Object.assign({}, state, payload);
    }

    case ActionTypes.SET_POPUP_OPEN_STATE: {
      return Object.assign({}, state, { isOpen: payload, isInitialViewed: true });
    }

    case ActionTypes.SET_VIDEO_STORAGE_STATE: {
      return Object.assign({}, state, { isInitialViewed: payload });
    }

    case ActionTypes.UPDATE_VIDEO_QUALITY: {
      return Object.assign({}, state,
        {
          currentVideoQuality: payload,
          currentVideoUrl: state.videos[payload as string]
        });
    }

    case ActionTypes.UPDATE_VIDEO_POSITION: {
      return Object.assign({}, state,
        {
          currentVideoPosition: payload
        });
    }

    default: {
      return state;
    }
  }
}

export const getVideoInfo = createSelector(
  (root: IRootState): IState => root.introPopup,
  ({
    currentVideoUrl,
    qualityList,
    currentVideoPosition,
    currentVideoQuality
  }: IState) => ({
    currentVideoUrl,
    qualityList,
    currentVideoPosition,
    currentVideoQuality
  }));

export const getIsIntroPopupOpen = createSelector(
  (root: IRootState): IState => root.introPopup,
  ({ isOpen }: IState): boolean => isOpen
);

export const getIsInitialViewed = createSelector(
  (root: IRootState): IState => root.introPopup,
  ({ isInitialViewed }: IState): boolean => isInitialViewed
);
