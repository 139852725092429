import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IFormInputChangePayload } from '../../abstract.components/abstract.form.component';

export const ActionTypes = {
  FORM_CONTROL_CHANGE: type('[Pii info popup] form control change'),
  SUBMIT_PII_INFO: type('[Pii info popup] submit form'),
  VALIDATE_SSN_DOB: type('[Pii info popup] validate ssn and dob'),
  STORE_SSN_DOB_VALIDITY: type('[Pii info popup] store ssn and dob validity'),
  SET_SSN_DOB_LOADING: type('[Pii info popup] set ssn and dob loading')
};

export class PiiInfoPopupControlChange implements Action {
  public type = ActionTypes.FORM_CONTROL_CHANGE;

  constructor(public payload: IFormInputChangePayload) {
  }
}

export class PiiInfoSubmit implements Action {
  public type = ActionTypes.SUBMIT_PII_INFO;

  constructor(public payload?: any) {
  }
}

export class ValidateSsnDob implements Action {
  public type = ActionTypes.VALIDATE_SSN_DOB;

  constructor(public payload: {
    ssn?: string;
    sin?: string;
    captcha: string;
  }) {}
}

export class StoreSsnDobValidity implements Action {
  public type = ActionTypes.STORE_SSN_DOB_VALIDITY;

  constructor(public payload: {
    valid: boolean;
    message: any;
  }) {}
}

export class SetSsnAndDobLoading implements Action {
  public type = ActionTypes.SET_SSN_DOB_LOADING;

  constructor(public payload: boolean) {}
}

export type TypeAction =
  PiiInfoPopupControlChange |
  PiiInfoSubmit |
  ValidateSsnDob |
  StoreSsnDobValidity |
  SetSsnAndDobLoading;
